import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"

import Img from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const AirportMasterPlansPage = () => {
  const data = useStaticQuery(graphql`
    query {
      tbdImg: file(relativePath: { eq: "master-plan.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Airport Master Plans" />
      <Hero
        pageTitle="Airport Master Plans"
        breadcrumbText="Services"
        breadcrumbLink="/#services"
      />
      <section className="section-1 highlights image-center">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <span className="pre-title m-0">
                Guidelines For Future Development
              </span>
              <h2>
                Airport Master{" "}
                <span className="featured">
                  <span>Plans</span>
                </span>
              </h2>
              <p className="text-max-800">
                Airport Master Plan Studies are designed to provide guidelines
                for future development of facilities at individual airports. The
                master plan identifies the staged airport facilities necessary
                to meet the requirements of forecast levels of air traffic
                demand with maximum operational effectiveness and with minimum
                cost and adverse impact on the community’s environment. Master
                plan studies also provide a thorough evaluation of the
                consequences of all reasonable and practical development
                alternatives at the airport.
              </p>
              <p>
                In developing these plans R.A. Wiedemann, Inc. has employed a
                variety of innovative ideas in solving problems. Using software
                applications, we have established forecasting procedures that
                enable the firm to rapidly produce and revise statistical
                analyses in order to provide more time to apply the judgmental
                process to the projections and to test various hypotheses on
                activity behavior or influences. This technique results in a
                more detailed, valid forecast of aviation activity.
              </p>
              <p>
                Innovation techniques are also employed in the facility
                requirements for landside facilities, aircraft noise modeling,
                and financial analyses.
              </p>

              <Link to="/contact">Contact us about Airport Master Plans</Link>
            </div>
            <div className="col-lg-4 mt-5">
              <div className="d-none d-lg-block report-shaddow">
                <Img fluid={data.tbdImg.childImageSharp.fluid} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AirportMasterPlansPage
